
#confident {
    padding-top: 118px;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 1;
}

#confident section#plain-header {
    padding: 80px 0;
}

#confident h1.page-headline,#confident h2.page-headline {
    font-weight: 900;
    font-size: 65px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -.01em;
    max-width: 780px;
    margin: 0 auto;
}

#confident ol,#confident p,#confident ul {
    margin-bottom: 32px;
}
#confident li,#confident p {
    font-size: 18px;
    line-height: 21px;
  font-family: Averta,Helvetica,Arial,sans-serif;
  color: #212e44;
}
#confident li {
    margin-bottom: 16px;
}
#confident p {
    margin-top: 0;
    margin-bottom: 1rem;
}
#confident h3.section-title-left {
    text-align: left;
    margin-top: 32px;
    margin-bottom: 32px;
}
#confident h3.section-title, h3.section-title-left {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700;
}
#confident h3.cta-text, h4.title-left {
    line-height: 30px;
    font-weight: 700;
}
#confident h4.title-left {
    font-size: 20px;
    text-align: left;
}
#confident a {
    color: #ff6726;
}
/* btn close Notification  */
.close-notif {
	position: absolute;
	right: 0px;
	/* top: 32px; */
	width: 32px;
	height: 32px;
	opacity: 0.3;
}
.close-notif:hover {
	opacity: 1;
}
.close-notif:before,
.close-notif:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 21px;
	width: 2px;
	background-color: #333;
}
.close-notif:before {
	transform: rotate(45deg);
}
.close-notif:after {
	transform: rotate(-45deg);
}

/* Invoice */
.invoice-light-gray-bar {
	color: #e4e6eb;
	font-size: 16px;
	text-align: right;
	font-family: 'Poppins SemiBold';
}

.invoice-black-text {
	color: black;
	font-size: 16px;
	text-align: right;
	font-family: 'Poppins SemiBold';
}

.invoice-dark-gray-text {
	color: #4b4f56;
	font-size: 16px;
	text-align: right;
	font-family: 'Poppins Bold';
}

.invoice-light-gray-text {
	color: #818e94;
	font-size: 16px;
	text-align: right;
	font-family: 'Poppins SemiBold';
}

/* Billing */
.billing-payments-table-bold-text {
	font-family: 'Poppins Bold';
	color: #4b4f56;
	text-align: left;
	font-size: 14px;
}
.billing-payments-table-semibold-text {
	font-family: 'Poppins SemiBold';
	color: #4d4f5c;
	text-align: left;
	font-size: 14px;
}
.billing-payments-table-medium-text {
	font-family: 'Poppins Medium';
	color: #4d4f5c;
	text-align: left;
	font-size: 14px;
}

.billing-payments-table-title {
	font-family: 'Poppins SemiBold';
	color: black;
	text-align: left;
	font-size: 14px;
}

.billing-payments-title {
	font-family: 'Poppins Medium';
	color: #4d4f5c;
	text-align: left;
	font-size: 18px;
}

.billing-plan-green-btn {
	background: #139216;
	border-radius: 5px;
	cursor: pointer;
}

.billing-plan-green-btn p {
	font-family: 'Poppins SemiBold';
	color: #fff;
	text-align: center;
	font-size: 14px;
}

.billing-plan-pink-btn {
	background: #e5007d;
	border-radius: 5px;
}

.billing-plan-pink-btn p {
	font-family: 'Poppins SemiBold';
	color: #fff;
	text-align: center;
	font-size: 14px;
}

.billing-plan-pink-text {
	font-family: 'Poppins SemiBold';
	color: #e5007d;
	font-size: 20px;
}

.billing-plan-gray-text {
	font-family: 'Poppins SemiBold';
	color: #4b4f56;
	font-size: 16px;
}

.billing-plan-lightgray-text {
	font-family: 'Poppins SemiBold';
	color: #818e94;
	font-size: 15px;
}

.billing-box {
	width: 665px;
	/* height: 402px; */
	background: #e4e6eb4d;
	border: 2px solid #e2137d;
	border-radius: 4px;
}

.billing-choice-box {
	width: 437px;
	height: 55px;
	background-color: #e4e6eb;
	border-radius: 2px;
	cursor: pointer;
}

.billing-choice-box p {
	color: #818e94;
	font-family: 'Poppins SemiBold';
	font-size: 16px;
}

.billing-choice-box:hover {
	width: 437px;
	height: 55px;
	background-color: #4080ff;
	border-radius: 2px;
}

.billing-choice-box:hover p {
	color: #fff;
	font-family: 'Poppins SemiBold';
	font-size: 16px;
}

.billing-choice-box-active {
	width: 437px;
	height: 55px;
	background-color: #4080ff;
	border-radius: 2px;
	cursor: pointer;
}

.billing-choice-box-active p {
	color: #fff;
	font-family: 'Poppins SemiBold';
	font-size: 16px;
}

/* DASHBOOOOARD */
.dashboard-post-card-full-image {
	width: 300px;
	height: 200px;
	/* background-repeat: no-repeat;
    background-position: center center; */
}

.dashboard-post-card {
	/* width: 300px; */
	height: auto;
	border-radius: 2px;
	background: white;
	box-shadow: 1px 1px 2px #00000005;
}
.dashboard-post-card p {
	font-family: 'Poppins SemiBold';
	font-weight: 600;
	font-size: 15px;
	color: #1c1c1b;
	margin: 0;
}

.dashboard-post-card span {
	font-family: 'Poppins Medium';
	font-size: 11px;
	color: #818e94;
}
.dashboard-post-card-date {
	font-family: 'Poppins Regular' !important;
	font-size: 11px !important;
	color: #818e94 !important;
}

.intent-dashboard-separator {
	/* border-width: 1px; */
	height: 0.5px;
	border-style: dashed;
	border-color: #b4b4b4;
	margin: 5px;
}

.intent-dashboard {
	border-radius: 2rem;
	height: 28px;
}

.intent-dashboard p {
	font-family: 'Poppins SemiBold';
	font-size: 14px;
	color: white;
}

.intent-dashboard-total {
	font-family: 'Poppins Medium';
	font-size: 15px;
	color: #818e94;
}

/* MODALS CONFIRMATION */
.confirm-modal-text {
	font-size: 15px;
	font-family: 'Poppins SemiBold';
	color: #818e94;
	text-align: center;
}

.cancel-btn-modal-text {
	font-size: 16px;
	font-family: 'Poppins SemiBold';
	color: #b4b4b4;
	text-align: center;
	cursor: pointer;
}

/* Team */
.gray-separator {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid red;
	margin: 1em 0;
	padding: 0;
}

.team-copy-span {
	background-color: #e5007d;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	width: 70%;
}

.team-token-container {
	border: 1px solid #b4b4b4;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: #e4e6eb;
}

.team-token-link {
	font-family: 'Poppins Medium';
	font-size: 13px;
	color: #818e94;
	width: 200px;
	height: 100%;
}

.team-invite-btn-gris {
	background-color: #b4b4b4;
	border: 1px solid #b4b4b4;
	color: white;
	font-family: 'Poppins Light';
	font-size: 13px;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	transition-duration: 600ms;
	font-weight: 600;
}
.team-invite-btn {
	background-color: #e5007d;
	border: 1px solid #e5007d;
	color: white;
	font-family: 'Poppins Light';
	font-size: 13px;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	transition-duration: 600ms;
	font-weight: 600;
}

.team-invite-btn:hover {
	background-color: white;
	color: #e5007d;
	border: 1px solid #e5007d;
	padding: 10px;
}

.team-invite-btn-no {
	background-color: white;
	border: 1px solid #e5007d;
	color: #e5007d;
	font-family: 'Poppins Light';
	font-size: 13px;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	transition-duration: 600ms;
	font-weight: 600;
}

.team-invite-btn-no:hover {
	background-color: #e5007d;
	color: #fff;
	border: 1px solid #e5007d;
	padding: 10px;
}

/* Wizard */
.wizard-config-textarea:focus {
	outline: #e5007d solid 1px;
	border: 0;
}

/* MANAGE MY PRODUCTS */
.synonym-box-gray {
	border: 1px solid #818e94;
	background-color: #e4e6eb;
	border-radius: 4px;
	/* padding: 8px */
}

.synonym-box-white {
	border: 1px dashed #818e94;
	background-color: white;
	border-radius: 4px;
	padding: 8px;
}

.synonym-box-white p {
	font-size: 13px;
	font-family: 'Poppins SemiBold';
	color: #818e94;
}

.synonym-box-gray p {
	font-size: 13px;
	font-family: 'Poppins SemiBold';
	color: #818e94;
}

.add-new-product-input {
	color: black;
	font-size: 14px;
	font-family: 'Poppins Medium';
	border: 0;
	background-color: transparent;
}

input:focus {
	outline: white solid 1px;
	border: 0;
}

.add-new-product-input-container {
	border: 1px solid #d7dae2;
	padding: 8px 10px;
	border-radius: 4px;
	background-color: white;
	box-shadow: 0px 2px 3px #0000000d;
}

.manage-products-search-input {
	border: 0;
}

.manage-products-search-input:focus {
	outline: white solid 1px;
	border: 0;
}

.manage-product-entity-name {
	color: #4d4f5c;
	font-size: 13px;
	font-family: 'Poppins Medium';
	letter-spacing: 0px;
}

.manage-product-entity-name:hover {
	color: #e5007d;
}

.manage-products-table-title {
	color: #1c1c1b;
	font-size: 15px;
	font-family: 'Poppins SemiBold';
	font-weight: 600;
}

.manage-products-search-container {
	background-color: white;
	padding: 10px;
	border: 1px solid #d7dae2;
	border-radius: 4px;
}

.manage-products-add-btn:hover p {
	color: #e5007d;
	font-size: 15px;
	font-family: 'Poppins SemiBold';
}

.manage-products-add-btn:hover {
	background-color: white;
	padding: 9px;
	border: 1px solid #e5007d;
	border-radius: 4px;
	cursor: pointer;
}

.manage-products-add-btn {
	background-color: #e5007d;
	padding: 10px;
	border-radius: 4px;
}

.manage-products-add-disabled-btn p {
	color: #818e94;
	font-size: 15px;
	font-family: 'Poppins medium';
}

.manage-products-add-disabled-btn:hover {
	cursor: not-allowed;
}

.manage-products-add-disabled-btn {
	background-color: #e4e6eb;
	padding: 10px;
	border-radius: 4px;
}

.manage-products-add-btn p {
	color: white;
	font-size: 15px;
	font-family: 'Poppins SemiBold';
}

.manage-products-popover {
	background-color: white;
	border: 2px solid #e5137d;
	border-radius: 5px;
	opacity: 1;
	padding: 10px;
	color: #4d4f5c;
	font-family: 'Poppins Medium';
	/* border: 2px solid #E5007D; */
	font-size: 12px;
	max-width: 350px;
	/* max-height: 90px; */
	margin-left: 10px;
	margin-top: -43px;
	z-index: 5;
}

.manage-products-popover-pageCard {
	background-color: white;
	border: 2px solid #e5137d;
	position: absolute;
	right: -83px;
	bottom: 10px;
	border-radius: 5px;
	opacity: 1;
	padding: 5px;
	color: #4d4f5c;
	font-family: 'Poppins Medium';
	font-size: 12px;
	width: 218px;
	max-height: 100px;
	z-index: 5;
}

/* MY AGENTS */
.my-agents-emoji-container {
	position: absolute;
	margin-left: 50px;
	margin-top: -360px;
	z-index: 1;
}

.my-agents-keyboard-container {
	position: absolute;
	margin-left: 92px;
	margin-top: -270px;
	z-index: 1;
}

.my-agent-icons-hover-text {
	font-family: 'Poppins Medium' !important;
	font-size: 13px !important;
	color: #fff;
}

.my-agents-profile-black-text {
	font-family: 'Poppins Medium';
	font-size: 12px;
	color: #4b4f56;
}

.my-agents-profile-gray-text {
	font-family: 'Poppins Medium';
	font-size: 12px;
	color: #b4b4b4;
}

.my-agents-profile-blue-text {
	font-family: 'Poppins Medium';
	font-size: 12px;
	color: #4080ff;
}

.my-agents-msgs-matched-btn {
	background-color: #009FE3;
	padding: 5px;
	border-radius: 4px;
	color: #fff;
	font-family: 'Poppins Medium';
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	align-self: center;
	transition-duration: 400ms;
}
#how-make ul li {
	font-family: 'Poppins Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
}
#how-make .rectangle{
	position: absolute;
	width: 2000px;
	height: 275px;
	background: rgba(0, 159, 227, 0.08);
	left: 75%;
	top: 0;
	right: 0;
}

#process .rectangle{
	position: absolute;
	width: 100px;
	height: 15rem;
	background: #FFED001C;
	left: 0;
	top: 30%;
}
#trail-business2 .rectangle {
    position: absolute;
    width: 17%;
    height: 52%;
    background: rgba(230, 0, 125, 0.06);
    left: 84%;
    top: 0;
}

#contact .rectangle {
    position: absolute;
    width: 2000px;
    height: 3rem;
    background: rgba(255, 237, 0, 0.15);
    left: 77%;
    top: -21px;
}
#contact .rectanglee {
    position: absolute;
    width: 353px;
    height: 3rem;
    background: rgba(229, 0, 125, 0.1);
    left: 0;
    bottom: -21px;
	z-index: 1;
}

#contact ::placeholder {
	font-family: 'Poppins Regular';
	font-style: normal;
	margin-left: 2rem;
	width: 100%;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	color: rgba(37, 37, 37, 0.26);
}
#contact input {
	padding-left: 1em;
}

#trail-business .rectangle {
    position: absolute;
    width: 47%;
    height: 2rem;
    background: rgba(229, 0, 125, 0.1);
	top:-4px;
    left: 0;
}
.my-agents-msgs-not-matched-btn {
	background-color: #e9e9eb;
	padding: 5px;
	border-radius: 4px;
	color: #818e94;
	font-family: 'Poppins Medium';
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	align-self: center;
	transition-duration: 400ms;
}

.my-agents-msgs-title {
	font-size: 16px;
	font-family: 'Poppins Medium';
	color: #4d4f5c;
}

.my-agents-msgs-tiny-icon {
	cursor: pointer;
}

.my-agents-msgs-tiny-box {
	height: 35px;
	width: 35px;
	border: 2px solid #b4b4b4;
	border-radius: 4px;
	background-color: #f0f1f2;
}

.my-agents-msgs-bulle-date {
	font-size: 14px;
	font-family: 'Poppins Regular';
	color: #d3d8da;
}

.my-agents-msgs-bulle-text {
	color: #293134;
	font-size: 14px;
	font-family: 'Poppins Regular';
	padding-left: 10px;
	padding-right: 10px;
}

.my-agents-msgs-bulle-bleu {
	padding: 10px;
	background-color: #edf0f5;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.my-agents-msgs-bulle {
	padding: 10px;
	background-color: #edf0f5;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

/* inverse  */

.my-agents-msgs-bulle-inverse {
	padding: 10px;
	background-color: #4080ff;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
}

.my-agents-msgs-bulle-text-inverse {
	color: white;
	font-size: 14px;
	font-family: 'Poppins Regular';
	padding-left: 10px;
	padding-right: 10px;
}
/* inverse  */

.my-agents-msg-intent {
	font-size: 13px;
	font-family: 'Poppins Medium';
	color: #818e94;
}

.my-agents-msg-desc {
	font-size: 13px;
	font-family: 'Poppins light';
	color: #293134;
	text-align: center;
}

.my-agents-msg-date {
	font-size: 13px;
	font-family: 'Poppins light';
	/* font-weight: 600;
    letter-spacing: 0.5px; */
	color: #b4b4b4;
}

.my-agents-msg-name {
	font-size: 13px;
	font-family: 'Poppins Bold';
	color: #818e94;
}

.my-agents-icon {
	cursor: pointer;
}

.my-agent-project-date {
	font-size: 14px;
	font-family: 'Roboto Regular';
	color: #4d4f5c;
}

.my-agent-project-time {
	font-size: 12px;
	font-family: 'Roboto Regular';
	color: #b4b4b4 !important;
}

.my-agent-project-name {
	font-size: 17px;
	font-family: 'Poppins Medium';
	color: #4d4f5c;
}

.my-agent-table-title {
	font-size: 17px;
	font-family: 'Poppins Medium';
	color: #1c1c1b;
	font-weight: 600;
	margin: 0;
}

.my-agents-table-label {
	font-size: 20px;
	font-family: 'Poppins Medium';
	color: #4d4f5c;
}

.my-agent-status-btn {
	background-color: #e5007d;
	padding: 10px 0px;
	cursor: pointer;
	border-radius: 3px;
	width: 70%;
	/* width: 90px;
    height: 38px ; */
}

.my-agent-status-btn-not-active {
	background-color: #b4b4b4;
	padding: 10px 0px;
	cursor: pointer;
	border-radius: 3px;
	width: 70%;
	/* width: 90px;
    height: 38px ; */
}

.my-agent-status-btn p,.my-agent-status-btn-not-active p {
	font-size: 12px;
	font-family: 'Poppins SemiBold';
	color: #fff;
}

/* .my-agent-status-btn:hover {
    color: #E5007D;
    background-color: white;
    /* padding: 9px 0px 9px 0px; 
    border: 1px solid #E5007D; 
}

.my-agent-status-btn:hover p{
    font-size: 12px;
    font-family: 'Poppins Medium';
    color: #E5007D;
} */

.my-agent-status-btn-disabled {
	background-color: #ccd2d4;
	padding: 10px 0px;
	cursor: pointer;
	border-radius: 3px;
	width: 70%;
	/* width: 90px;
    height: 38px ; */
}

.my-agent-status-btn-disabled p {
	font-size: 12px;
	font-family: 'Poppins SemiBold';
	color: #818e94;
}

.my-agent-title-table {
	font-size: 15px;
	font-family: 'Poppins SemiBold';
	color: #1c1c1b;
}

/* PAGES STYLING */
.page-card-owner-name {
	color: #4d4f5c;
	font-family: 'Poppins Medium';
	font-size: 16px;
}

.page-card-owner-checkbox-active {
	height: 7px;
	width: 7px;
	background-color: #e5007d;
}

.page-card-owner-checkbox {
	height: 15px;
	width: 15px;
	border: 2px solid #b4b4b4;
}

.pages-filter-box {
	border: 1px solid #d7dae2;
	background-color: white;
	border-radius: 4px;
}

.pages-filter-box p {
	font-family: 'Poppins Medium';
	color: #b4b4b4;
	font-size: 13px;
}

.filter-item {
	background-color: white;
	color: #818e94;
	font-family: 'Poppins SemiBold';
}

.filter-item:hover {
	background-color: #e5007d;
	color: white;
	cursor: pointer;
}

.filter-item:hover p {
	color: white;
}

.filter-item p {
	color: #818e94;
	font-family: 'Poppins SemiBold';
	font-size: 11px;
}

.slick-prev:before,
.slick-next:before {
	color: #e5007d !important;
}

.slick-dots li button:before {
	color: #e5007d !important;
	opacity: 0.75;
}

.slick-dots {
	position: absolute;
	bottom: 12px !important;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
}

.slick-slide div {
	outline: none;
}

.page-card-disabled-title-container {
	z-index: 1;
	margin-top: -50px;
}

.page-card-disabled-title {
	color: #e5007d;
	font-family: 'Roboto Bold';
	font-size: 15px;
	text-align: center;
	bottom: 25%;
}

.page-card-modal-small-title {
	color: #818e94;
	font-family: 'Poppins SemiBold';
	font-size: 15px;
}
.page-card-modal-small-title-hover {
	color: #e5007d;
	font-family: 'Poppins SemiBold';
	font-size: 15px;
}

.page-card-modal-small-box {
	height: 60px;
	width: 60px;
	border: 1px solid #b4b4b4;
}

/* .page-card-modal-small-box:hover {
    border: 1px solid #E5007D; 
}

.page-card-modal-small-box:hover .page-card-modal-small-title {
    color:#E5007D; 
} */

.page-card-connect-popover {
	padding: 5px;
	background-color: white;
	color: #e5007d;
	font-family: 'Poppins Bold';
	font-size: 13px;
	border: 2px solid #e5007d;
	position: absolute;
	margin-top: -44px;
	margin-left: -45px;
	cursor: pointer;
	border-radius: 5px;
	padding: 10px;
}

.react-numeric-input b {
	background: transparent !important;
	border: 0 !important;
	box-shadow: none !important;
}

.page-card-anti-spam-save-btn {
	border: 1px solid #4080ff;
	background: #4080ff;
	font-size: 12px;
	color: #fff;
	font-family: 'Poppins Medium';
	text-align: center;
	border-radius: 2px;
	transition-duration: 600ms;
}
.page-card-anti-spam-save-btn:hover {
	background: white;
	color: #4080ff;
	border: 1px solid #4080ff;
}

#page-card-anti-spam-input {
	width: 36px;
	height: 24px;
	color: #4b4f56;
	font-family: 'Poppins Medium';
	font-size: 11px !important;
	border: 1px solid #b4b4b4;
	background-color: white;
}

.page-card-anti-spam-unity {
	color: #4b4f56;
	font-family: 'Poppins SemiBold';
	font-size: 12px;
}

.page-card-anti-spam-checkbox-label {
	color: #4b4f56;
	font-family: 'Poppins Medium';
	font-size: 12px;
}

.page-card-anti-spam-small-text {
	color: #4b4f56;
	font-family: 'Poppins SemiBold';
	font-size: 12px;
}

.page-card-anti-spam-switch-label-public-reply {
	color: #818e94;
	font-family: 'Poppins medium';
	font-size: 11px;
	cursor: pointer;
}
.page-card-anti-spam-switch-label-public-reply:hover {
	color: #e5137d;
	font-family: 'Poppins medium';
	font-size: 11px;
	cursor: pointer;
}

.page-card-anti-spam-switch-label {
	color: #818e94;
	font-family: 'Poppins medium';
	font-size: 11px;
}

.page-card-anti-spam-popover {
	background-color: white;
	position: absolute;
	z-index: 2;
	margin-top: 15px;
	margin-left: -67.3px;
	width: 280px;
	height: 285px;
	cursor: pointer;
	padding: 10px 16px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
}
 .price-button {
	font-family: monospace;
}
.formulaire-pricing .price-button {
	font-weight: 700;
    font-size: 17px;
	font-family: monospace;
}
/* .page-card-anti-spam-popover {
    background-color: white;
    position: absolute;
    z-index: 2;
    margin-top: 22px;
    margin-left: -98px;
    width: 312px;
    height: 290px;
    cursor: pointer;
    padding: 10px 16px;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    -moz-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
} */

.pages-card-profile-picture {
	max-height: 50px;
	border-radius: 50%;
}

.owl-carousel .owl-item img {
	width: auto !important;
	display: initial !important;
}

.pages-card-desc-2 {
	font-size: 12px;
	font-family: 'Poppins Medium';
	color: #818e94;
	margin-top: -5px;
}

.pages-card-title {
	font-size: 15px;
	text-align: center;
	font-family: 'Poppins SemiBold';
	color: #2d2f39;
}

.pages-card {
	width: 240px;
	height: 240px;
	padding: 5px 25px 10px 25px;
	border: 2px solid #e5137d;
	border-radius: 4px;
	transition-duration: 500ms;
	/* border-color: red; */
}

.pages-card-active {
	width: 240px;
	height: 240px;
	border-radius: 4px;
	padding: 5px 25px 10px 25px;
	box-shadow: 0px 0px 6px 6px #00000029;
	-webkit-box-shadow: 0px 0px 6px 6px #00000029;
	-moz-box-shadow: 0px 0px 6px 6px #00000029;
	/* box-shadow: 1px 1px 2px #00000005; */
	-ms-transform: scale(1.05); /* IE 9 */
	-webkit-transform: scale(1.05); /* Safari 3-8 */
	transform: scale(1.05);
}

.home-big-title {
	font-size: 27px;
	font-family: 'Poppins medium';
	color: #1c1c1b;
	font-weight: 600;
}

.spinnerRose {
	color: #e5007d !important;
}

.spinnerWhite {
	color: #fff !important;
}

.my-agents-search-input {
	border: 0;
	outline: none !important;
	font-family: 'Poppins Light';
	font-weight: 700;
	letter-spacing: 0.5px;
	font-size: 13px;
	letter-spacing: 0px;
	color: #4d4f5c;
	opacity: 1;
}
.my-agents-search-input:focus {
	outline: none !important;
}

.button-myAgents-traite {
	background-color: #149545;
	border-radius: 4px;
	opacity: 1;
	color: white;
	text-align: center;
	font-size: 14px;
	font-family: 'Roboto Medium';
	padding: 8px 15px;
	border: 0;
	outline: none !important;
}

.Bloc-Scroll {
	background-color: white;
	border-radius: 4px;
	box-shadow: 1px 1px 2px #00000005;
	overflow: auto;
	/* height: 550px */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Bloc-Scroll::-webkit-scrollbar {
	display: none;
}

.myAgent-config-quick-replies-btn {
	background-color: #009ee3;
	border-radius: 4px;
	cursor: pointer;
	color: white;
	font-family: 'Poppins Medium';
	font-size: 12px;
	padding: 5px 0px;
	width: 100%;
	height: 29px;
	outline: none;
	border: 0;
}
/* .myAgent-config-quick-replies-btn:hover {
    background-color: white;
    color: #009EE3;
    border: 1px solid #009EE3;
} */
.myAgent-config-quick-replies-btn:focus {
	outline: none;
}
.recherche-team {
	background-color: #ffffff;
	border: 1px solid #d7dae2;
	border-radius: 4px;
	opacity: 1;
}
.team-members-titre {
	font-size: 18px;
	font-family: 'Poppins SemiBold';
	font-weight: 600;
	letter-spacing: 0px;
	color: #4b4f56;
	opacity: 1;
}
.team-members-titre:focus {
	outline: none;
}
.zoom-icon {
	position: absolute;
	right: 25px;
	top: 20px;
}
.item-pagination-team {
	background-color: #ffffff !important ;
	opacity: 1 !important;
	border: 1px solid #e8e9ec !important;
	border-radius: 4px !important;
	color: #4d4f5c !important;
	font-family: 'Poppins Medium' !important;
	text-decoration: none !important;
	font-size: 12px !important;
}
.item-pagination-team:hover {
	background-color: #e5007d !important ;
	border: 1px solid #ffffff !important;
	color: #ffffff !important;
	opacity: 1 !important;
	text-decoration: none !important;
}

.team-name {
	color: #818e94;
	font-size: 13px;
	font-family: 'Poppins Light';
	font-weight: 600;
	letter-spacing: 0px;
}

.team-description {
	color: #818e94;
	font-size: 12px;
	font-family: 'Poppins Light';
	font-weight: 600;
	letter-spacing: 0px;
}

.team-button-send {
	background-color: #e5007d;
	border: 1px solid #e5007d;
	color: white;
	font-family: 'Roboto Bold';
	font-size: 12px;
	border-radius: 4px;
	cursor: pointer;
	transition: 600ms;
	padding: 0.375rem 1.2rem;
}
.team-button-send:hover {
	background-color: white;
	color: #e5007d;
	border: 1px solid #e5007d;
}
.team-close {
	color: #b4b4b4;
	background-color: white;
	border: 0;
	font-size: 30px;
}
.team-close:focus {
	outline: none;
}

* {
	box-sizing: border-box;
}

.progressbar {
	text-align: center;
	width: 100%;
	zoom: 5;
}
.counter div {
	background-color: white;
	display: inline-block;
	border: 1px solid grey;
	border-radius: 50%;
	width: 2em;
	line-height: 1.88em;
	vertical-align: top;
}

.counter td {
	cursor: pointer;
	text-align: center;
	position: relative;
}

.counter span {
	position: absolute;
	top: 1em;
	left: -50%;
	width: 100%;
	border-top: 2px solid grey;
	z-index: -100;
}

.progressbar .active,
.progressbar .active * {
	border-color: red;
	color: red;
}

/*.counter td:not(:first-child):before
	content: '';
	position: absolute;
	top: 2em;
	display: block;
	border-top: 2px solid grey;
}*/

.progressbar .descriptions {
}

.span-hover-manageProduct {
	position: absolute;
	z-index: 3;
	width: 175px;
	margin-left: 140px;
	margin-top: -15px;
	text-align: center;
	border: 2px solid #e5007d;
	background-color: white;
	border-radius: 4px;
}
.span-hover-manageProduct-text {
	color: #e5007d;
	font-family: Poppins SemiBold;
	font-size: 12px;
}
.pop-up-filter-myAgents {
	position: absolute;
	z-index: 2;
	width: 321px;
	right: 0px;
	top: 45px;
	background: #fff;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
	padding: 25px 0px;
	font-family: 'Poppins Medium';
}
.pop-up-filter-myAgents-titre {
	color: #747474;
	font-size: 14px;
}
.pop-up-filter-myAgents-select {
	color: #959595;
	border: 1px solid #d3d3d3;
	border-radius: 5px;
	opacity: 1;
	font-size: 13px;
	padding: 0px 2px;
}
.pop-up-calender-myAgents {
	position: absolute;
	z-index: 2;
	width: 321px;
	right: -80px;
	top: 45px;
	background: #fff;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
	padding: 5px;
}
.react-calendar {
	width: 321px;
	max-width: 100%;
	background: white;
	border: 0 !important;
	font-family: 'Poppins Medium';
	line-height: 1px;
	font-size: 14px;
}

.MuiSlider-root {
	color: #e5007d !important;
	width: 100%;
	cursor: pointer;
	height: 2px;
	display: inline-block;
	padding: 13px 0;
	position: relative;
	box-sizing: content-box;
	touch-action: none;
	-webkit-tap-highlight-color: transparent;
}
.MuiSlider-thumb {
	width: 15px !important;
	height: 15px !important;
	display: flex;
	outline: 0;
	position: absolute;
	box-sizing: border-box;
	margin-top: -6px;
	transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	align-items: center;
	margin-left: -6px;
	border-radius: 50%;
	justify-content: center;
	background-color: white !important;
	border: 3px solid #e5137d;
}
.slick-list {
	height: 370px !important;
}

.sous-text-selectPage {
	font-family: 'Poppins medium';
	font-size: 14px;
	color: #4d4f5c;
	opacity: 1;
}
.add-category-page-name {
	color: #4b4f56;
	font-family: 'Roboto medium';
	font-size: 14px;
}
.add-category-category-name {
	color: #4b4f56;
	font-family: 'Poppins medium';
	font-size: 17px;
}
.popover-tags-my-agents {
	max-width: 160px !important;
	box-shadow: 0px 0px 6px 3px #0000001f !important;
	border: 1px solid #b4b4b4 !important;
	border-radius: 5px !important;
	position: absolute;
	z-index: 1;
	background-color: white;
	bottom: 2.5% !important;
	left: 4% !important;
}

.zoom {
	transition: transform 0.2s;
	/* z-index: 4; */
	/* margin: 0 auto; */
}

.zoom:hover {
	-ms-transform: scale(1.05); /* IE 9 */
	-webkit-transform: scale(1.05); /* Safari 3-8 */
	transform: scale(1.05);
	/* z-index: 6; */
}

/* 
.button-message-facebook{
    background-color: #e4e6eb;
    border: 1px solid #e4e6eb;
    color: black;
    border-radius: 3px;
    transition-duration: 1s;
    width: 100%;
    padding: 5px;
}
.button-message-facebook:hover{
    background-color: #caccd2;
    border: 1px solid #e4e6eb;
    color: black;
    border-radius: 3px;
} */

@media (max-width: 566px) {
	.pages-filter-box p {
		font-size: 9px;
	}
	.w-xs-100 {
		width: 100%;
	}
	.price-colone-grise, .price-colone{
		margin: 0 20px;
	}
	.price-titre{
		margin-left: 10px !important;
	}
	.price-credit-card{
		margin-left: 4% !important;
	}
}


html, body {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
}
.full-height {
  height: 100%;
  background: yellow;
}
@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Regular'), url('./assets/fonts/Poppins-Regular.woff') format('woff');
}
  
  
@font-face {
  font-family: 'Poppins Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Italic'), url('./assets/fonts/Poppins-Italic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Thin'), url('./assets/fonts/Poppins-Thin.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Thin Italic'), url('./assets/fonts/Poppins-ThinItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraLight'), url('./assets/fonts/Poppins-ExtraLight.woff') format('woff');
}


@font-face {
  font-family: 'Poppins ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraLight Italic'), url('./assets/fonts/Poppins-ExtraLightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light'), url('./assets/fonts/Poppins-Light.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light Italic'), url('./assets/fonts/Poppins-LightItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Medium'), url('./assets/fonts/Poppins-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Medium Italic'), url('./assets/fonts/Poppins-MediumItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins SemiBold'), url('./assets/fonts/Poppins-SemiBold.woff') format('woff');
}


@font-face {
  font-family: 'Poppins SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins SemiBold Italic'), url('./assets/fonts/Poppins-SemiBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold'), url('./assets/fonts/Poppins-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold Italic'), url('./assets/fonts/Poppins-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraBold'), url('./assets/fonts/Poppins-ExtraBold.woff') format('woff');
}


@font-face {
  font-family: 'Poppins ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraBold Italic'), url('./assets/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Black';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Black'), url('./assets/fonts/Poppins-Black.woff') format('woff');
}


@font-face {
  font-family: 'Poppins Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Black Italic'), url('./assets/fonts/Poppins-BlackItalic.woff') format('woff');
}

 /* font roboto */

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Medium'), url('./assets/fonts/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Regular'), url('./assets/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold'), url('./assets/fonts/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Light'), url('./assets/fonts/Roboto-Light.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Thin'), url('./assets/fonts/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black'), url('./assets/fonts/Roboto-Black.woff') format('woff');
}





/* roboto-regular - latin */


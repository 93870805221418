/* ************** FOOTER *********** */
#footer-modo {
	position: relative;
	background-color: white;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	/* margin: 40px 0 120px;*/
	padding: 1em 6%;
	flex-wrap: wrap;
}

#footer-home input {
	border: 3px solid #009FE3;
	padding-left: 2rem;
    border-radius: 9px;
    height: 45px;
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
#footer-home ::placeholder {
	font-family: 'Poppins Regular';
	font-style: normal;
	margin-left: 2rem;
	width: 100%;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	color: rgba(37, 37, 37, 0.26);
}
#footer-home ul li:first-child{
	font-family: 'Poppins Medium';
	font-weight: 700;
}

#footer-home ul li,#footer-home ul li a{
	font-family: 'Poppins Regular';
	font-weight: 400;
	font-size: 18px;
	color: #000;
}

#footer-home ul {
    list-style: none;
    line-height: 4;
}
#footer-modo p {
	font-size: 16px;
	line-height: 23px;
	color: #344563;
	margin: 0;
}
#footer-modo .policy-links {
	flex-wrap: wrap;
	justify-content: center;
}
#footer-modo .policy-links a {
	margin-right: 16px;
	margin-left: 16px;
	font-size: 14px;
	line-height: 20px;
	color: #344563;
	text-decoration: underline;
}

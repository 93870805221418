
/* Side Menu */
.side-menu-title {
    color: #818E94;
    font-family: 'Poppins Medium';
    font-size: 14px;
}


.side-menu-wizard-text {
   color: white;
   font-family: 'Poppins SemiBold';
   font-size: 13px;
   text-align: center;
}

.side-menu-wizard-btn {
    /* padding: 10px; */
    background-color: #E5137D;
    border-radius: 5px;
    text-decoration: none;
    width: 80%;
    box-shadow: 0px 3px 6px #00000029;
}

.side-menu-wizard-text-grise {
   color: #B4B4B4;
   font-family: 'Roboto Medium';
   font-size: 12px;
   text-align: center;
}

.side-menu-wizard-btn-grise {
    /* padding: 10px; */
    background-color: #E4E6EB ;
    border-radius: 5px;
    text-decoration: none;
    width: 80%;
    box-shadow: 0px 3px 6px #00000029;
}






/* Navbar */
.navbar-arrow-icon {
    cursor: 'pointer';
    margin-top: 15px!important;
}

.navbar-container {
    background: 'red'
}


.navbar-menu-title {
    font-family: 'Poppins Medium';
    color: #818E94;
    font-size: 15px;
    user-select: none;
   cursor: pointer;
}
  
.navbar-menu-title:hover {
    color: #E5137D;
}
.logout-mobile path{
    fill: #fff;
}
.navbar-menu-style {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}
.free-trial{
    background: #E5007D 0% 0% no-repeat padding-box;
    opacity: 1;
}

.text-free-trial{
    text-align: left;
    font-family: Poppins medium;
    font-size: 17px ;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.button-free-trial{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 0;
    opacity: 1;
    padding: 13px 30px 13px 30px;
    font-size:  16px ;
    font-family: Poppins bold;
    letter-spacing: 0px;
    color: #E5007D;
    margin: auto;
}
.button-free-trial:focus{
    outline: none;
}
.text-button-free-trial{
    font-size:  16px ;
    font-family: Poppins bold;
    letter-spacing: 0px;
    color: #E5007D;
}


.dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
    background-color: #2980B9;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    padding: 0px 20px;
    top: 64px;
    right: 18px;
    overflow: auto;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: #818E94;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* .dropdown a:hover {background-color: #ddd;} */

.show {display: block;}

.show-video iframe{
    height: 50vh;
}
.landing-big-text {
	font-family: 'Poppins SemiBold';
	font-size: 30px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0px;
	color:  #E5007D;
	opacity: 1;
}
.present-home >div:first-child{
    margin-bottom: 3rem;
}
#our-solution {
     padding-top: 50px; 
     padding-bottom: 100px;
}
#our-solution .list img {
    object-fit: contain;
    width: 13rem;
}
#prebuilt-solution{
    padding-bottom: 3em;
}
#how-make{
    padding-top: 6em;
    padding-bottom: 2em;
}
#how-make .row .col-{
    padding-left: 0;
    padding-right: 0;
}
#process{
    padding-top: 7em;
}

@media(max-width: 767px) {
    .landing-big-text {
        font-size: 28px;
    }
    .container-fluid {
        margin-top: 1rem !important;
    }
    .present-home >div:first-child{
        margin-bottom: 0rem;
    }
    #our-solution {
        padding-top: 0px; 
        padding-bottom: 10px;
    }
    #our-solution .list img {
        width: 9rem;
    }
    #prebuilt-solution{
        padding-bottom: 1em;
    }
    #how-make{
        padding-top: 1em;
        padding-bottom: 0em;
    }
    #how-make .list{
        margin-right: 0em;
        margin-left: 0em;
    }
   
    #process{
        padding-top: 2em;
    }
    #process .title{
        padding-bottom: 0em;
    }
    #process .height-color{
        height: unset !important;
    }
    #trail-business{
        padding-top: 0rem !important;
    }
    #footer-home .list{
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
   
}
@media (max-width: 566px) {
	.landing-big-text {
		font-size: 21px;
	}
    .landing-big-text,.landing-desc,.landing-desc{
        margin-left: 1rem;
    }
    #how-make ul{
        padding-left: 0;
    }
    #how-make ul li{
        font-size: 14px;
    }
    #how-make ul li img{
       width: 15px !important;
    }
}
@media (max-width: 500px) {
	.show-video iframe{
		height: 40vh;
	}
}



.show-video .modal-content {
     background-color: transparent; 
     border: none; 
	 border-radius: 20px;
}
.landing-page-slogan {
	font-family: 'Poppins SemiBold';
	font-size: 17px;
	color: #818e94;
}
.container-fluid{
	margin-top: 5rem;
}
.contact-form-button {
	background: #e5007d;
	color: #fff;
	border: 1px solid #e5007d;
	font-family: 'Poppins Bold';
	font-size: 18px;
	width: 300px;
	cursor: pointer;
	transition: 600ms;
}

.contact-form-button:hover {
	background: #fff;
	color: #e5007d;
	transition: 600ms;
}

#contact-form-label {
	font-family: 'Poppins SemiBold';
	font-size: 14px;
	color: #4b4f56;
}

#contact-form-input {
	border-radius: 0px;
	border-color: #b4b4b4;
	border-width: 2px;
}

#contact-form-input:focus {
	outline: none;
	box-shadow: none;
	border: 2px solid #b4b4b4;
}

.contact-us-title {
	font-family: 'Poppins Bold';
	font-size: 30px;
	color: #e5007d;
}

.no-credit-required {
	font-family: 'Poppins Medium';
	font-size: 16px;
	color: #818e94;
}

.landing-card-btn {
	font-family: 'Poppins Medium';
	font-size: 15px;
	color: #e5007d;
}

.landing-card-desc {
	font-family: 'Poppins Regular';
	font-size: 13px;
	color: #818e94;
}

.landing-card-desc-blue {
	font-family: 'Poppins SemiBold';
	font-size: 16px;
	color: #4080ff;
}

.landing-card-title {
	font-family: 'Poppins SemiBold';
	font-size: 20px;
	color: black;
}
/* .landing-card-title:hover {
    color: white;
} */

.landing-card-icon {
	max-width: 70px;
}

.landing-card {
	width: 400px;
	padding: 40px;
	-webkit-box-shadow: 0px 6px 10px #0000001a;
	-moz-box-shadow: 0px 6px 10px #0000001a;
	box-shadow: 0px 6px 10px #0000001a;
	background-color: white;
}
/* hover landing card  */
/* .landing-card:hover  {
  background-color: #E5007D ;
} 
.landing-card:hover  .landing-card-title {
  color: white;
} 
.landing-card:hover  .landing-card-desc {
  color: white;
} 
.landing-card:hover  .landing-card-btn {
  color: white;
} 
.landing-card:hover  .landing-card-icon {
  color: white;
}  */

.landing-texts-container {
	margin: 80px 0px 80px 0px;
}

.landing-desc {
	font-family: 'Poppins Regular';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 0px;
	line-height: 25px;
	opacity: 1;
}


.facebookbtn {
    border: 3px solid #009FE3;
    border-radius: 0.5em;
    background-color: #fff;
    padding: 0.3em 2em;
	margin: 0 1.5px;
    box-sizing: border-box;
}
.facebookbtn.large {
    border-radius: 15px !important;
}
.large{
	padding: 0.8em 2em;
}

.facebookbtn-bg{
    background-color: #009FE3;
}
.facebookbtn-bg span {
	color:  #fff !important;
}
.facebookbtn span {
/* 	color: #fff;
	font-size: 15px;
	font-family: 'Poppins Medium'; */
	cursor: pointer;
	font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
	color:  #009FE3;
	letter-spacing: 0em;
	text-align: left;
}

.facebookbtnLanding {
	border: none;
	padding: 15px 55px;
	border-radius: 4px;
	background: #e5137d;
}

.facebookbtnLanding span {
	color: #fff;
	font-size: 20px;
	font-family: 'Poppins Medium';
	cursor: pointer;
}
/* .button-free-trial{
  background: #E5137D 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
} */

/**************** Docs Page ****************/

.titre-menu-docs {
	font: 'Roboto Medium';
	font-size: 15px;
	letter-spacing: 0px;
	color: #4080ff;
	margin: 0;
}

.sous-titre-menu-docs {
	font: 'Roboto Medium';
	font-size: 12px;
	letter-spacing: 0px;
	color: #818e94;
	opacity: 1;
}

.sous-titre-menu-docs:hover {
	color: #e5007d !important;
	/* text-decoration: none; */
	cursor: pointer;
}

.sous-titre-menu-docs-selected {
	font: 'Roboto medium';
	font-size: 12px;
	letter-spacing: 0px;
	color: #e5007d;
	opacity: 1;
	background: #efefef;
	border-radius: 5px;
}

.sous-titre-menu-docs-selected:hover {
	color: #e5007d !important;
}

.docs-side-menu {
	box-shadow: 1px 1px 2px #0000000d;
	opacity: 1;
}

.docs-big-box {
	width: 1088px;
	max-height: 552px;
}

.docs-bullet {
	background-color: #e5007d;
	border-radius: 0px 50px 50px 0px;
	width: 50px;
	height: 10px;
}
.box-docs {
	box-shadow: 0px 0px 36px #0000001a;
	border-radius: 10px;
	background-color: white;
}
.text-box-docs {
	font-family: 'Poppins SemiBold';
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0px;
	color: #1c1c1b;
	opacity: 1;
}

.desc-box-docs {
	font-family: 'Poppins medium';
	font-size: 13px;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
	width: 90%;
}

li {
	font-family: 'Poppins medium';
	font-size: 13px;
	letter-spacing: 0px;
	color: #000;
	opacity: 1;
	/* width: 90%; */
}

ul {
	padding-left: 18px;
	line-height: 2;
}
.side-menu-docs {
	height: 76%;
	overflow-y: scroll;
	width: 17%;
}
.side-menu-docs::-webkit-scrollbar {
	display: none;
}
.image-landing-page {
	width: 100%;
	object-fit: contain;
}



/* pricing style CSS */

.price-titre {
	font-weight: bold;
	font-size: 22px;
	color: #4b4f56;
}
.price-sous-titre {
	font-weight: bold;
	font-size: 16px;
	color: #e5007d;
}
.price-titre-item {
	font-family: 'Poppins SemiBold';
	font-weight: bold;
	font-size: 17px;
	/* line-height: 42px; */

	color: #1c1c1b;
}
.price-price {
	font-weight: bold;
	font-size: 40px;
	color: #818e94;
}
.price-sous-titre-price {
	font-weight: 600;
	font-size: 13px;
	color: #1c1c1b;
}
.price-checkbox-titre {
	font-weight: 600;
	font-size: 13px;
	text-align: left;
	color: #818e94;
}
.price-credit-card {
	font-weight: 600;
	font-size: 12px;
	color: #b4b4b4;
}
.price-button {
	border-radius: 4px;
	background: #e5007d;
	border: 1px solid#e5007d;
	font-weight: bold;
	font-size: 15px;
	width: 100%;
	padding: 10px;
	color: #fff;
	transition-duration: 0.5s;
	outline: none;
}
.price-button:hover {
	background: #fff;
	border: 1px solid#e5007d;
	color: #e5007d;
}
.price-button:disabled {
	background: rgb(255, 255, 255, 0.5);
	border: 1px solid rgb(229, 0, 125, 0.5);
	color: rgb(229, 0, 125, 0.5);
}
.price-button-grise {
	border-radius: 4px;
	background: #e4e6eb;
	border: 1px solid#E4E6EB;
	font-weight: bold;
	font-size: 15px;
	width: 100%;
	padding: 10px;
	color: #818e94;
	transition-duration: 1s;
	outline: none;
}
.page-price {
	width: 100%;
	background-color: #f9f9f9;
	font-family: 'Poppins Medium';
}
.list-plan{
	margin-left: 3%;
	margin-right: 3%;
}
.price-colone {
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
	padding: 30px 10px 10px 10px;
	transition-duration: 0.5s;
	border: 1px solid #f5f5f5;
}
.price-colone-grise {
	border-radius: 20px;
	background: #f9f9f9;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.1);
	padding: 30px 10px 10px 10px;
	/* transition-duration: 0.5s; */
	border: 1px solid #9fa0a3;
}

.colone {
	border: 0px;
}

.colone:hover .price-colone {
	border: 1px solid #e5007d;
}
.colone:hover .price-titre-item {
	color: #e5007d;
}

.price-icon-size {
	width: 6%;
}

.formulaire-pricing {
	background: #ffffff;
	box-shadow: 0px 20px 20px #0000001f;
	border-radius: 5px;
	width: 700px;
	margin: auto;
	padding: 30px 10px;
	/* height: 1280px; */
	transition-duration: 0.5s;
}
.formulaire-pricing h1 {
	font-size: 20px;
	font-family: 'Poppins SemiBold';
	letter-spacing: 0px;
	color: #4b4f56;
}
.formulaire-pricing h2 {
	font-size: 17px;
	font-family: 'Poppins Medium';
	letter-spacing: 0px;
	color: #4b4f56;
}
.formulaire-pricing label {
	font-size: 15px;
	font-family: 'Poppins SemiBold';
	letter-spacing: 0px;
	color: #818e94;
}
.formulaire-pricing p {
	font-size: 14px;
	font-family: 'Poppins SemiBold';
	letter-spacing: 0px;
	color: #818e94;
	margin: 20px 0px 0px 0px;
}
.formulaire-pricing input {
	background: #ffffff;
	border: 1px solid #b4b4b4;
	border-radius: 5px;
	width: fit-content;
	width: 100%;
	height: 100%;
	padding: 5px;
	font-size: 14px;
}
.formulaire-pricing input:focus {
	background: #ffffff;
	border: 1px solid #b4b4b4;
	border-radius: 5px;
}
.formulaire-pricing hr {
	border: 1px solid #b4b4b4;
	opacity: 1;
	width: 100%;
	margin: 0px 0px 20px 0px;
}

.formulaire-pricing-checkbox-box {
	border-radius: 5px;
	background: #fff;
	padding: 7px;
	border: 1px solid #b4b4b4;
}
.formulaire-pricing div,
img {
	transition-duration: 0.5s;
}
.formulaire-pricing-checkbox-box-rose {
	border-radius: 5px;
	background: #fff;
	padding: 7px;
	border: 1px solid #e2137d;
}
.formulaire-pricing-checkbox-box-rose p {
	color: #e2137d;
}
.formulaire-pricing #total-amount {
	font-family: 'Poppins SemiBold';
	font-weight: 600;
	font-size: 18px;
	color: #b4b4b4;
	margin: auto 10px auto 0;
}
.formulaire-pricing #price {
	font-family: 'Poppins SemiBold';
	font-weight: 600;
	font-size: 30px;
	color: #e5007d;
	margin: auto 10px auto 0;
}
.formulaire-pricing #tax {
	font-family: 'Poppins SemiBold';
	font-weight: 600;
	font-size: 13px;
	color: #1c1c1b;
	margin: auto 0 auto 0;
}
.show-video iframe{
	height: 50vh;
}

@media (max-width: 500px) {
	.image-landing-page {
		max-width: 100%;
	}
	.landing-card > div{
		height: unset !important;
	}
	.show-video iframe{
		height: 40vh;
	}
}
